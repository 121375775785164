search-info-box
    display: flex
    flex-direction: column
    width: 100%
    gap: 1rem
    margin-top: 1rem
    font-family: "Alegreya Sans"

    > button 
        display: flex
        flex-direction: row
        align-items: center
        background-color: #F4F9F8
        padding: 0 0.5rem

        > div
            display: flex
            flex-direction: column
            text-align: left
            padding: 1rem

            > div
              > p.info-box-heading
                  font-weight: bold
                  color: #2F4165

              > svg
                padding-right: -2rem
                filter: invert(38%) sepia(80%) saturate(6298%) hue-rotate(142deg) brightness(99%) contrast(95%)

            > p.info-box-desc
                padding-right: 2rem
                color: #6B7280

        > div.search-button
          > div
            background-image: url('/imgs/search.svg')
            background-repeat: no-repeat
            background-size: cover
            filter: opacity(30%)

            margin-top: 0.75rem
            width: 3rem
            height: 3rem

            &:hover
              transition-duration: 200ms 
              filter: opacity(100%) invert(34%) sepia(54%) saturate(1892%) hue-rotate(120deg) brightness(96%) contrast(95%)
