@use 'breakpoints'

editor
  // .editor-container
  .editor-input
    padding: 4.7244100440202rem
    height: 70.2rem
    border: 0.1rem solid #A9A9A9
    border-color: #A9A9A9
    outline-width: 0
    overflow: auto
    width: 100%

    @include breakpoints.bp-lg
      width: 49.8rem

    .ltr
      text-align: left

    .rtl
      text-align: right

    .editor-heading-one
      font-size: 24

    .editor-theme-bold
      font-weight: bold

    .editor-theme-italic
      font-style: italic

    .editor-theme-underline
      text-decoration: underline

    .editor-theme-link
      text-decoration: underline
      background-color: #F4F9F8
      color: #089444

  // input:focus,
  // select:focus,
  // textarea:focus,
  // button:focus
  //   outline: none
