@use 'breakpoints' 

@mixin toolbar-els-focused-state()
  &:focus
    outline: none

toolbar
  flex-direction: row
  align-items: center
  place-content: center

  > div.col-flex
    display: flex
    flex-direction: column

    > div.list-items
      display: flex
      flex-direction: row
      column-gap: 12rem
      row-gap: 2rem
      justify-content: space-between
      margin-top: 2rem

      @media screen and (max-width: 750px)
        flex-direction: column

      > history
        display: flex
        flex-direction: column
        row-gap: 2rem
        justify-content: space-between

        > label
          display: block
          margin-right: 1rem

          @include toolbar-els-focused-state

        > select
          background-color: white
          border: 1px solid #D1D5DB
          padding: 0.5rem

          @include toolbar-els-focused-state
      > sort-by
        display: flex
        flex-direction: column

    > tools
      display: flex
      flex-direction: row
      justify-content: flex-end
      margin-top: 2rem
