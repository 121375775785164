report-index
    > div
        margin-top: 4rem
        margin-bottom: 4rem
        margin-left: auto
        margin-right: auto
        max-width: 48rem
        display: flex
        flex-direction: column
        background-color: #f3f4f6
        padding: 1rem

        speaker
            margin-top: 2rem

        heading
            margin-left: 1rem