@use 'breakpoints'
  
@mixin super-search-over-page()
  height: 150vh
  width: 100%
  top: 0
  left: 0
  right: unset
  padding-inline: 1rem
  border: none
  border-top: .625rem solid #089444
  border-radius: unset

@mixin super-search-focused-state()
  &:focus,
  &:focus-within
    position: absolute
    border-color: rgba(34, 197, 94)
    left: 0
    right: 0
    top: 6rem
    
    @include breakpoints.bp-compact
      @include super-search-over-page

@mixin search-button()
  button
    z-index: 20
    background-image: url('/imgs/search.svg')
    background-repeat: no-repeat
    background-size: cover
    position: absolute
    filter: opacity(30%)
    bottom: 0px
    right: 4px
    

    &:hover
      transition-duration: 200ms 
      filter: opacity(100%) invert(34%) sepia(54%) saturate(1892%) hue-rotate(120deg) brightness(96%) contrast(95%)


super-search
  display: block
  position: static
  z-index: 10
  flex-direction: column

  margin-inline: auto

  background-color: white
  border: solid 2px #F0EFEF
  transition: border 200ms
  border-radius: .25rem

  padding-left: 0.25rem
  padding-right: 0.25rem

  min-width: 100%
  max-width: 20%

  @include breakpoints.bp-sm
    min-width: 38rem

  @include super-search-focused-state

  > input
    z-index: 10
    grid-area: search
    outline: none
    padding: .75rem 3.5rem .75rem 1.5rem
    min-width: 95%
    background-color: transparent

    &::placeholder
      color: #AAA
      font-size: 1rem
      // @media screen and (max-width: 600px)
      //   font-size: .8rem

    &.input-error::placeholder
      color: #FF5733

  > div.run-search-button
    position: relative

    @include search-button()
    > button
      width: 2.5rem
      height: 2.5rem

    > search-results
      grid-area: results
