@mixin accordion-button()
    min-width: 100%
    z-index: 10
    padding: 0.75rem 1.5rem
    text-align: left
    font-weight: 600

@mixin accordion-div()
    z-index: 10
    padding: 2rem
    border: 2px solid
    border-radius: 0 0 0.4rem 0.4rem

search-history
    > div
        margin-left: auto
        margin-right: auto
        width: 100%
        max-width: 38rem
        text-align: center

        single-accordion
            > button
                @include accordion-button()
                background-color: #069444 !important
                color: #FFFFFF
                font-weight: 600

            > button.active
                border-radius: 0.4rem 0.4rem 0 0

            > button.inactive
                border-radius: 0.4rem

            > div
                @include accordion-div()
                border-color: #069444
                border-radius: 0 0 0.4rem 0.4rem
