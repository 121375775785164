// TODO: add @fontface declarations and remove unused variants, see twind config below

@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700&display=swap')
@import url('https://rsms.me/inter/inter.css')
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap')
@import url('https://use.typekit.net/eao1ujk.css')

// From twind config, might be useful
// {
//     fontFamily: "Inter",
//     src: "url(/static/fonts/inter/Inter-Regular.woff)",
// },
// {
//     fontFamily: "Inter",
//     fontStyle: "italic",
//     src: "url(/static/fonts/inter/Inter-Italic.woff)",
// },
// {
//     fontFamily: "Merriweather",
//     src: "url(/static/fonts/merriweather/Merriweather-Regular.ttf)",
// },
// {
//     fontFamily: "Merriweather",
//     fontStyle: "light",
//     src: "url(../static/fonts/merriweather/Merriweather-Light.ttf)",
// },
// {
//     fontFamily: "Merriweather",
//     fontStyle: "bold",
//     src: "url(../static/fonts/merriweather/Merriweather-Bold.ttf)",
// },
