@mixin accordion-button()
    min-width: 100%
    z-index: 10
    padding: 0.75rem 1.5rem
    text-align: left
    font-weight: 600

@mixin accordion-div()
    z-index: 10
    padding: 2rem
    border: 2px solid
    border-radius: 0 0 0.4rem 0.4rem
    
article-summary
    display: flex
    flex-direction: column
    margin-left: auto
    margin-right: auto
    gap: 2rem
    max-width: 72rem
    margin-top: 2rem
    font-family: 'Open Sans'

    catchwords
        max-width: 100%
        single-accordion
            > button
                @include accordion-button()    
                color: white
                background-color: #089444 !important

            > button.active
                border-radius: 0.4rem 0.4rem 0 0

            > button.inactive
                border-radius: 0.4rem

        
            > div
                @include accordion-div()
                background-color: #F4F9F8
                color: #2F4165
                border: 0px
                height: 30rem
                overflow-y: scroll                
                
                > p
                    margin-bottom: 1rem
                    


    representation
        single-accordion
            > button
                @include accordion-button()
                background-color: #C0DEE6 !important
                color: #384b6D

            > button.active
                border-radius: 0.4rem 0.4rem 0 0

            > button.inactive
                border-radius: 0.4rem

            > div
                @include accordion-div()
                border-color: #C0DEE6
                border-radius: 0 0 0.4rem 0.4rem

    similarity-trace
        single-accordion
            > button
                @include accordion-button()
                background-color: #C0E3D0 !important
                color: #00575B
                font-weight: 600

            > button.active
                border-radius: 0.4rem 0.4rem 0 0

            > button.inactive
                border-radius: 0.4rem

            > div
                @include accordion-div()
                border-color: #C0E3D0
                border-radius: 0 0 0.4rem 0.4rem

    litigation-history
        single-accordion
            > button
                @include accordion-button()
                background-color: #F5CBC6 !important
                color: #CD3423
                font-weight: 600

            > button.active
                border-radius: 0.4rem 0.4rem 0 0

            > button.inactive
                border-radius: 0.4rem

            > div
                @include accordion-div()
                border-color: #F5CBC6
                border-radius: 0 0 0.4rem 0.4rem

    incoming-citations
        single-accordion
            > button
                @include accordion-button()
                background-color: #DDD3CA !important
                color: #865670
                font-weight: 600

            > button.active
                border-radius: 0.4rem 0.4rem 0 0

            > button.inactive
                border-radius: 0.4rem

            > div
                @include accordion-div()
                border-color: #DDD3CA
                border-radius: 0 0 0.4rem 0.4rem

    outgoing-citations
        single-accordion
            > button
                @include accordion-button()
                background-color: #CFCFCF !important
                color: #696969
                font-weight: 600

            > button.active
                border-radius: 0.4rem 0.4rem 0 0

            > button.inactive
                border-radius: 0.4rem

            > div
                @include accordion-div()
                border-color: #CFCFCF
                border-radius: 0 0 0.4rem 0.4rem
