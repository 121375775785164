page-layout
  display: flex
  flex-direction: column
  min-width: 100%

  ::selection
    background: #E8F3E8

  > page-content
    min-height: 38rem

jasmine-page-layout
  display: block

  > page-layout > page-content
    margin: 0 1rem


  > page-layout > jasmine-footer
    margin-top: 12rem

    @media screen and (min-width: 600px)
      margin-top: 12rem


    @media screen and (min-width: 2000px)
      margin-top: 12rem
