summary-headnote
    > div
        > div
            display: flex
            flex-direction: row
            justify-content: space-between

            > div
                > h1
                    font-family: 'Merriweather'
                    font-size: xx-large
                    font-weight: bold
                    color: #069444

                > h2
                    font-family: 'Open Sans'
                    font-size: large
                    color: #069444
                    
                > h3
                    font-family: 'Open Sans'
                    font-size:  medium
                    color: #384b6D

            > button
                height: 4rem
                font-family: 'Alegreya Sans'
                font-weight: bold
