@use 'breakpoints'

@mixin header-a-selected()
  color: #EEE
  background-color: rgba(0, 0, 0, 0.1)

site-header
  background-color: #089444

  header
    display: flex
    grid-template-columns: 5rem auto
    margin-inline: auto

    justify-content: space-between
    align-items: center

    max-width: 80rem
    padding-inline: 0.25rem

    font-size: 14px

    div#logo
      display: flex
      place-content: center

    nav
      display: flex

      a
        padding: 10% 1rem
        color: white
        font-weight: bold
        cursor: pointer

        &:hover
          transition-duration: 200ms
          @include header-a-selected()
        
      a.selected
        @include header-a-selected()
        transition-duration: 200ms
        box-shadow: inset 0 -0.5rem 0 #C0E3D0

      button
        color: white
        border: solid 2px white
        margin: 1rem
        padding: .6rem 1.25rem
        font-weight: bold
        border-radius: .6rem

        &:hover
          transition-duration: 200ms
          color: green
          background-color: white

    @include breakpoints.bp-mobile
      max-height: none

      > nav
        justify-content: center

    @include breakpoints.bp-lg
      padding-inline: 0

      > nav
        display: block

