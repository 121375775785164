// WIDTH VARIABLES
$max-w-7xl: 80rem
$max-w-6xl: 72rem
$max-w-5xl: 64rem
$max-w-4xl: 56rem
$max-w-3xl: 48rem
$max-w-2xl: 42rem
$max-w-xl: 36rem
$max-w-lg: 32rem
$max-w-md: 28rem
$max-w-sm: 24rem
$max-w-xs: 20rem

// MEDIA QUERY MIXINS
@mixin bp-mobile
  @media screen and (max-width: 640px)
    @content

@mixin bp-sm
  @media screen and (min-width: 640px)
    @content

@mixin bp-compact
  @media screen and (max-width: 700px)
    @content

@mixin bp-md
  @media screen and (min-width: 768px)
    @content

@mixin bp-lg
  @media screen and (min-width: 1024px)
    @content

@mixin bp-xl
  @media screen and (min-width: 1280px)
    @content

@mixin bp-2xl
  @media screen and (min-width: 1536px)
    @content

