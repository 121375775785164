@use 'breakpoints'
    
headnote
    display: flex
    > div
        margin-top: 4rem
        margin-bottom: 4rem
        margin-left: auto
        margin-right: auto
        font-family: 'Source Sans Pro'

        @include breakpoints.bp-md
            max-width: 48rem
        @include  breakpoints.bp-lg
            min-width: 52rem

        detail
            > div
                margin-bottom: 4rem

        catchwords
            > div
                background-color: #f3f4f6
                padding: 1rem

                > p
                    margin-top: 1rem

        representation
            > div
                background-color: #f3f4f6
                padding: 1rem
                margin-top: 4rem

                > p
                    margin-top: 1rem
