jasmine-info
  display: flex
  align-items: center
  justify-content: center
  margin-top: 2rem
  padding: 2rem 4rem

  background-color: #F4F9F8

results-list
  max-width: 80%
