table
    font-family: 'Open Sans'

    > thead
        > tr
            > th
                padding: 0 0 0.5rem 0.5rem
                text-align: left
                font-weight: 600
                color: #384b6D

    > tbody
        > tr
            > td
                padding: 0.5rem 0 0.5rem 0.5rem
                font-weight: 500
                color: #384b6D
